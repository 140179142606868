<template>
  <div id="balance">
    <top :name="text" />
    <div class="di">
      <div class="absolt">
        <p class="font">可提现余额</p>
        <p
          class="font"
          style="
            border-bottom: 1px solid white;
            padding-bottom: 0.3rem;
            padding-left: 0.2rem;
            padding-top: 0.2rem;
            font-size: 0.46rem;
          "
        >
          {{mydock.balance}} <span style="font-size: 0.3rem">元</span>
        </p>
        <div class="flex">
          <div>
            <p style="font-size: .36rem;">{{mydock.withdrawn_cash}}</p>
            <p>已提现(元)</p>
          </div>
          <div>
            <p style="font-size: .36rem;">{{mydock.cumulative}}</p>
            <p>累计收益(元)</p>
          </div>
          <div>
            <p style="font-size: .36rem;">{{mydock.received}}</p>
            <p>待到账(元)</p>
          </div>
        </div>
      </div>
    </div>
    <van-tabs animated swipeable v-model="activeName">
      <van-tab title="最近收益" name="1">
       <div class="div_shouzhi" v-for="(item,index) in mydock.balance_record" :key="index">
          <div style="line-height: .4rem;">
            <p style="letter-spacing: .02rem;">{{item.title}}</p>
            <p style="color: gray;letter-spacing: .02rem;">{{item.establish_time}}</p>
          </div>
          <div style="font-size: 0.3rem">+{{item.money}}</div>
        </div>
      </van-tab>
      <van-tab title="最近提现" name="2">
       <div class="div_shouzhi" v-for="(item,index) in mydock.transmit_tixian" :key="index">
          <div style="line-height: .4rem;">
            <p style="letter-spacing: .02rem;">{{item.wal_static}} ({{item.category}})</p>
            <p style="color: gray;letter-spacing: .02rem;">{{item.apply_time}}</p>
          </div>
          <div style="font-size: 0.3rem">-{{item.money}}</div>
        </div>
      </van-tab>
    </van-tabs>
    <div style="width: 100%; height: 1.2rem"></div>
    <div class="bottom">
      <span>注：提现需1-3个工作日审核打款</span>
      <button @click="show = true">提 现</button>
    </div>

    <!-- 提现弹窗 -->
    <!-- <van-popup position="bottom" v-model="show">
      <div class="p_div">
        <p class="p">请选择提现方式</p>

        </div>
      
    </van-popup> -->
    <van-action-sheet v-model="show" title="请选择提现方式">
      <div class="content">
        <div class="fangshi">
          <div :class="active == 1 ? 'on' : ''" @click="active = 1">
            <img class="oo" src="../assets/zhifubao.png" alt="" />
            <p>支付宝</p>
          </div>
          <div :class="active == 2 ? 'on' : ''" @click="active = 2">
            <img class="oo" src="../assets/ying.png" alt="" />
            <p>银行卡</p>
          </div>
          <div></div>
        </div>
        <!-- 填写信息 -->
        <van-cell-group>
          <!-- 金额 -->
          <van-field
            v-model="money"
            type="digit"
            label="金额"
            placeholder="请输入提现金额"
          />
          <!-- 银行卡开卡人姓名 -->
          <van-field
            v-if="active == 2"
            v-model="yhk_name"
            label="姓名"
            placeholder="请输入开卡人姓名"
          />
          <!-- 银行全称 -->
          <van-field
            v-if="active == 2"
            v-model="yinghang"
            label="银行全称"
            placeholder="农业银行/招商银行"
          />
                    <!-- 银行卡号 -->
          <van-field
            v-if="active == 2"
            v-model="yinghang"
            label="银行卡号"
            placeholder="请输入银行卡号"
          />
          <!-- 支付宝姓名 -->
          <van-field
            v-if="active == 1"
            v-model="zhi_name"
            label="姓名"
            placeholder="请输入支付宝实名姓名"
          />
          <!-- 支付宝手机号码 -->
          <van-field
            v-if="active == 1"
            v-model="phone"
            type="tel"
            label="手机号码"
            placeholder="请输入手机号码"
          />
        </van-cell-group>
      </div>
      <button @click="tixian" class="but">立即提现</button>
    </van-action-sheet>
  </div>
</template>

<script lang="ts">
import top from "../components/filltop.vue";

export default {
  data() {
    return {
      text: "我的余额",
      activeName: "1",
      show: false,
      money: "",
      zhi_name: "",
      phone: "",
      active: 1,
      yinghang: "",
      yhk_name: "",
      // 渲染参数
      mydock : {},
    };
  },
  components: {
    top,
  },
  methods: {
    tixian(){
      this.$toast.fail('即将开通')
    }
  },
  mounted() {
    this.$https(
      'mydack/balance',
      "id="+sessionStorage.getItem("user_id") +
      "&phone="+sessionStorage.getItem("user_phone"),
      'post'
    ).then(res=>{
      if(res.data.code == '000000'){
        this.mydock = res.data.data
      }else{
        this.$toast.fail(res.data.msg)
      }
    })
  },
};
</script>

<style scoped>
#balance {
  height: 100%;
  background: #f8f8f8;
}
.di {
  width: 100%;
  position: relative;
  height: 4rem;
  background: #008acb;
}
.absolt {
  width: 96%;
  height: 3.3rem;
  bottom: 0;
  right: 0;
  left: 0;
  color: white;
  margin: 0 auto;
  position: absolute;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  background: linear-gradient(to right, #603ad7, #46ebff);
  padding: 0.3rem;
  /* background-image: linear-gradient(to right #6371f1 #e84444); */
}
.font {
  font-size: 0.32rem;
  display: block;
}
.flex {
  width: 100%;
  height: auto;
  font-size: 0.28rem;
  text-align: center;
  justify-content: space-between;
  display: flex;
}
.flex div {
  padding-top: 0.36rem;

  /* flex: 1; */
}
.div_shouzhi {
  display: flex;
  padding: 0.2rem;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-bottom: 1px solid #efefef;
  font-size: 0.26rem;
}
.bottom {
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  background: white;
  display: flex;
  font-size: 0.28rem;
  justify-content: space-between;
  padding: 0.2rem;
  align-items: center;
}
.bottom button {
  color: white;
  width: 40%;
  padding: 0.2rem 0.2rem 0.2rem 0.2rem;
  border: none;
  border-radius: 0.2rem;
  background: #3f3394;
  font-size: 0.3rem;
}
.p_div {
  width: 100%;
  height: auto;
  padding: 0.2rem;
}
.p {
  font-size: 0.3rem;
}
.fangshi {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.3rem;
}
.fangshi div {
  margin-bottom: 0.2rem;
  flex: 1;
  text-align: center;
}
.oo {
  width: 0.5rem;
  margin: 0 auto;
  height: 0.5rem;
}
.content {
  padding: 16px 16px 160px;
  position: relative;
}
.on {
  color: #0056ff;
}
.but {
  width: 66%;
  border-radius: 0.2rem;
  height: auto;
  letter-spacing: 0.02rem;
  border: none;
  font-size: 0.4rem;
  padding: 0.1rem 0 0.1rem 0;
  background: #068ed3;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0.2rem;
}
</style>